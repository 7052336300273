import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {},
  },

  helpWrapper: {
    padding: theme.spacing(2),
    maxWidth: "50vw",
  },

  itemMainInfoWrapper: {
    padding: theme.spacing(4, 0, 2, 0),
  },

  titleWrapper: {
    padding: theme.spacing(2),

    "& h6": {
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: "underline",
    },
  },
}))
