import { Box, Divider, Grid, Typography } from "@material-ui/core/"
import * as React from "react"
import { Pagination } from ".."
import theme from "../../../App.theme"
import { Icon } from "../../../helpers/Types"
import { ActivityLoader } from "../../_common"
import ListTitles from "./ListTitles"
import Row, { IRowAction } from "./Row"

import useStyles from "./styles"

let FixedSizeList = null

if (typeof window !== "undefined") {
  FixedSizeList = require("react-window").FixedSizeList
}

export interface IChip {
  active: boolean
  class: string
  disabledClass: string
  icon: Icon
}

export interface IDataTableConfig {
  listTitles: string[]
  colProps: string[]
  chips: IChip[]
  rowHeight: number
  idProp: string
  pagina: number
  numPag: number
  numReg: number
  registrosPorPagina: number
  paginator: () => (string | number)[]
  paginationAction: (page: number) => void
  nullText?: string
}

interface IDataTable {
  items: any[]
  config: IDataTableConfig
  handleRowClick?: (id: string, index: number) => void
  rowActions?: IRowAction[]
  loader?: boolean
  paginate?: boolean
  trueHeight?: number
  discrete?: boolean
  titleClick?: (i: number) => void
  orden?: boolean
}

const DataTable = React.forwardRef<HTMLDivElement, IDataTable>((props, ref) => {
  const {
    items,
    config,
    handleRowClick,
    rowActions,
    loader,
    paginate,
    trueHeight,
    discrete,
    titleClick,
    orden
  } = props
  const {
    listTitles,
    colProps,
    chips,
    rowHeight,
    idProp,
    pagina,
    numPag,
    numReg,
    paginator,
    paginationAction,
    registrosPorPagina,
    nullText
  } = config

  const classes = useStyles({ discrete })

  return (
    <div className={ classes.root } { ...{ ref } }>
      { listTitles && items && items.length !== 0 && (
        <>
          <ListTitles
            titles={ listTitles }
            { ...{ rowActions, titleClick, orden } }
          />
          <Divider />
        </>
      ) }
      <Grid container direction="column" className={ classes.dataTableWrapper }>
        <Grid item xs>
          { loader ? (
            <Grid
              container
              item
              className={ classes.loaderContainer }
              style={ { height: trueHeight } }
            >
              <ActivityLoader text="Cargando datos..." />
            </Grid>
          ) : items && items.length !== 0 ? (
            typeof window !== "undefined" ? (
              <FixedSizeList
                height={ trueHeight ? trueHeight : 100 }
                itemSize={ rowHeight }
                itemCount={ items.length }
                itemData={ {
                  items,
                  colProps,
                  chips,
                  handleRowClick,
                  rowActions,
                  idProp,
                  nullText
                } }
              >
                { Row }
              </FixedSizeList>
            ) : (
              <>
                { !discrete && (
                  <Box height={ trueHeight } px={ 1 }>
                    <Typography
                      variant="h5"
                      className={ classes.emptyListeMessage }
                    >
                      No se encontraron elementos.
                    </Typography>
                  </Box>
                ) }
              </>
            )
          ) : (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={ { height: trueHeight ? trueHeight : 100 } }
            >
              <Typography
                variant="h6"
                style={ { color: theme.palette.text.disabled } }
              >
                Busca datos de la PNIF
              </Typography>
            </Grid>
          ) }
        </Grid>
        { paginate && (
          <Grid item>
            <Pagination
              options={ {
                pagina,
                numPag,
                numReg,
                paginator,
                registrosPorPagina
              } }
              fetchData={ paginationAction }
            />
          </Grid>
        ) }
      </Grid>
    </div>
  )
})

export default DataTable
