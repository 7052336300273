import { Grid } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import { Button } from "../../_common"

import useStyles from "./styles"


interface IExportTab {
  exportAction: any
  isSearchAvailable: boolean
}

const ExportTab: FC<IExportTab> = ({ exportAction, isSearchAvailable }) => {
  const styles = useStyles()

  const handleExportFilteredData = async () => {
    await exportAction()
  }

  return (
    <Grid container className={ styles.root }>
      <Grid item xs container justify="center">
        <Button
          aria="Exporta tu consulta"
          small
          handleClick={ handleExportFilteredData }
          disabled={ !isSearchAvailable }
        >
          Exportar consulta
        </Button>
      </Grid>
    </Grid>
  )
}

export default ExportTab
