import { Grid, Typography } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import { HiOutlineDatabase } from "react-icons/hi"
import theme from "../../../App.theme"
import { bytesToSize } from "../../../helpers/helperFunctions"
import { IArchivosDB } from "../../../pages/datos"
import { Button } from "../../_common"
import { SectionText, SectionTitle } from "../../_layout"

import useStyles from "./styles"


interface IOtrosDatos {
  content: string
  archivos: IArchivosDB[]
}

const OtrosDatos: FC<IOtrosDatos> = ({ content, archivos }) => {
  const styles = useStyles()

  return (
    <Grid container className={ styles.root }>
      <SectionTitle title="Descarga otras bases de datos" />

      <SectionText text={ content } />

      <Typography variant="h6" className={ styles.h6 }>
        Archivos para descargar
      </Typography>

      { archivos.map(archivo => (
        <Grid container key={ archivo.id } className={ styles.item }>
          <Grid item>
            <Typography variant="body1" component="span">
              { archivo.titulo }
            </Typography>
            <Typography variant="body1" component="p">
              { archivo.contenido }
            </Typography>
          </Grid>

          <Grid container item direction="column" className={ styles.info }>
            <Grid item>
              <Typography variant="body2" component="span">
                <strong>Archivo</strong>: { archivo.filename }
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body2" component="span">
                <strong>Tamaño</strong>: { bytesToSize(archivo.fileSize) }
              </Typography>
            </Grid>
          </Grid>
          <Grid className={ styles.buttonContainer }>
            <Button
              small
              discrete
              aria={ `Descarga ${ archivo.titulo }` }
              handleClick={ () =>
                typeof window !== "undefined" && window.open(archivo.archivoURL)
              }
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs style={ { marginRight: theme.spacing(1) } }>
                  <span style={ { color: theme.palette.primary.main } }>
                    Descargar
                  </span>
                </Grid>

                <Grid xs item spacing={ 1 } container>
                  <Grid
                    item
                    xs
                    container
                    justify="center"
                    alignItems="center"
                    style={ { color: theme.palette.extras.orange } }
                  >
                    <HiOutlineDatabase />
                  </Grid>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      )) }
    </Grid>
  )
}

export default OtrosDatos
