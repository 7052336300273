import React, { FC } from "react"
import { Grid, Typography } from "@material-ui/core"

import useStyles from "./styles"

interface IDBDictionary {}

const DBDictionary: FC<IDBDictionary> = ({}) => {
  const styles = useStyles()

  return (
    <Grid item container className={styles.root}>
      <Typography component="h6">Diccionario de datos</Typography>
      <Grid item container className={styles.dictionaryWrapper}>
        <Typography variant="body1">
          El diccionario de datos se descarga junto con la base de datos
          completa de municipios.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default DBDictionary
