import { makeStyles } from "@material-ui/core"
import theme, { ITheme } from "../../../App.theme"

interface IStyleProps {}

export const multiSelectStyle = {
  chips: {
    background: theme.palette.text.lightText,
    color: theme.palette.primary.main,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
    display: "inline-block",
    justifyContent: "space-between",
    borderRadius: theme.roundness,
  },
  searchBox: {
    border: "none",
    borderBottom: `1px solid ${theme.palette.text.lightMild}`,
    borderRadius: 0,
    color: theme.palette.text.lightText,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2, 0),
  },
}

export default makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4, 2),
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {},
  },

  helpContainer: {
    "& p": {
      margin: theme.spacing(2, 0),
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.lightText,
    },
  },

  cleanFilters: {
    alignItems: "center",
    justifyContent: "flex-end",
    color: theme.palette.text.lightText,

    cursor: "pointer",

    "& span": {
      margin: theme.spacing(2),
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  heading: {
    justifyContent: "flex-start",
    alignItems: "center",

    "& h6": {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(0, 2),
      width: "100%",
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.lightText,
      textTransform: "uppercase",
    },
  },
}))
