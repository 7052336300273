import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

export default makeStyles((theme: ITheme) => ({
  paginationRoot: {
    height: 85,
  },

  paginationButtonActive: {
    minWidth: theme.spacing(4),
    height: theme.spacing(4),
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0.5),
    backgroundColor: theme.palette.extras.yellow,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  paginationButtonNotActive: {
    minWidth: theme.spacing(4),
    height: theme.spacing(4),
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0.5),
    backgroundColor: "transparent",
    fontSize: theme.typography.body2.fontSize,
    border: `1px ${theme.palette.primary.main} solid`,
  },
  paginationButtonArrow: {
    minWidth: theme.spacing(4),
    height: theme.spacing(4),
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0.5),
    backgroundColor: "transparent",
    fontSize: theme.typography.body2.fontSize,
  },
  paginationWrapper: {
    margin: theme.spacing(2, 0),
  },

  textClass: {
    color: theme.palette.text.primary,
  },
}))
