import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core"
import * as React from "react"
import { FC, useState } from "react"

import { Accordion } from "../../_common"
import useStyles from "./styles"

interface IRadioItem {
  value: string | number
  label: string
}

interface ICategoryMultipleOption {
  value: string | number
  setValue: (v: string) => void
  options: IRadioItem[]
  title: string
  selectionCallback:
    | ((item: string) => void)
    | ((item: string) => Promise<void>)
  disabled?: boolean
}

const CategoryMultipleOption: FC<ICategoryMultipleOption> = ({
                                                               title,
                                                               options,
                                                               selectionCallback,
                                                               disabled,
                                                               value,
                                                               setValue,
                                                             }) => {
  const styles = useStyles()

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true)

  const handleChange = event => {
    setValue(event.target.value)
    selectionCallback(event.target.value)
    setIsAccordionExpanded(false)
  }

  return (
    <Grid container>
      <Accordion
        expanded={isAccordionExpanded}
        onChange={() => setIsAccordionExpanded(prev => !prev)}
        { ...{ title, disabled } }
        content={
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="rango de cajeros"
              name="rangoCajeros"
              value={ value }
              onChange={ handleChange }
            >
              { options?.map((e: IRadioItem, i: number) => (
                <FormControlLabel
                  classes={ {
                    label: styles.radioLabel
                  } }
                  key={ i }
                  value={ e.value }
                  control={
                    <Radio
                      color="default"
                      classes={ {
                        root: styles.radio,
                        checked: styles.radioChecked
                      } }
                    />
                  }
                  label={ e.label }
                />
              )) }
            </RadioGroup>
          </FormControl>
        }
      />
    </Grid>
  )
}

export default CategoryMultipleOption
