import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
}

export default makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 4),
      maxWidth: 1440,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },

  link: {
    color: "blue",
    cursor: "pointer"
  },

  videoModalWrapper: {
    width: "70vw",
    maxHeight: "90vh"
  }
}))
