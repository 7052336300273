import { Box, Grid, Tooltip } from "@material-ui/core/"
import * as React from "react"
import { FC } from "react"
import theme from "../../../App.theme"
import useStyles from "./styles"

interface ITitle {
  title: string
  onClick?: () => void
  withPointer?: boolean
}

const Title: FC<ITitle> = ({ title, onClick, withPointer }) => {
  const classes = useStyles({})
  return (
    <Grid item xs { ...{ onClick } }>
      <Tooltip { ...{ title } }>
        <Box
          fontWeight="fontWeightBold"
          fontSize={ theme.typography.body2.fontSize }
          className={ `${ withPointer && classes.withPointer } ${ classes.title }` }
        >
          { title }
        </Box>
      </Tooltip>
    </Grid>
  )
}

export default Title
