import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { GiMexico } from "react-icons/gi"
import { HiOutlineDatabase } from "react-icons/hi"
import { RiFolderHistoryLine, RiSurveyLine } from "react-icons/ri"
import { Layout } from "../components/_layout"

import { DataIndex, DataIndexENIF, DataIndexHistory, GeneralTabs, OtrosDatos } from "../components/OpenData/"

const getDatosAbiertosData = graphql`
    query {
        dbData: allContentfulContenidoDatosAbiertosBasesDeDatos {
            edges {
                node {
                    municipios {
                        municipios
                    }
                    historicos {
                        historicos
                    }
                    enif {
                        enif
                    }
                    otrosDatos {
                        otrosDatos
                    }
                }
            }
        }

        archivosData: allContentfulBasesDeDatosElemento {
            edges {
                node {
                    id
                    titulo
                    contenido
                    imagen {
                        file {
                            fileName
                            details {
                                size
                            }
                            url
                        }
                    }
                }
            }
        }
    }
`

interface IDBS {
  municipios: string
  historicos: string
  enif: string
  otrosDatos: string
}

export interface IArchivosDB {
  id: string
  titulo: string
  contenido: string
  fileSize: number
  filename: string
  archivoURL: string
}

const Datos: React.FC = () => {
  const { dbData, archivosData } = useStaticQuery(getDatosAbiertosData)

  const dbs: IDBS = dbData.edges.map(({ node }: any) => ({
    municipios: node.municipios.municipios,
    historicos: node.historicos.historicos,
    enif: node.enif.enif,
    otrosDatos: node.otrosDatos.otrosDatos
  }))[0]

  const archivos: IArchivosDB[] = archivosData.edges.map(({ node }: any) => ({
    id: node.id,
    titulo: node.titulo,
    contenido: node.contenido,
    fileSize: node.imagen.file.details.size,
    filename: node.imagen.file.fileName,
    archivoURL: node.imagen.file.url
  }))

  const opendataTabs = [
    {
      title: "Datos administrativos",
      component: <DataIndex content={ dbs.municipios } />,
      icon: <GiMexico />
    },
    {
      title: "Datos históricos",
      component: <DataIndexHistory content={ dbs.historicos } />,
      icon: <RiFolderHistoryLine />
    },
    {
      title: "ENIF",
      component: <DataIndexENIF content={ dbs.enif } />,
      icon: <RiSurveyLine />
    },
    {
      title: "Otros datos",
      component: <OtrosDatos content={ dbs.otrosDatos } { ...{ archivos } } />,
      icon: <HiOutlineDatabase />
    }
  ]
  return (
    <Layout
      withNav
      seoTitle="Datos"
      seoDescription="Explora y descarga"
    >
      <GeneralTabs { ...{ opendataTabs } } />
    </Layout>
  )
}

export default Datos
