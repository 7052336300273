// noinspection DuplicatedCode

import { Grid } from "@material-ui/core"

import { base64StringToBlob, createObjectURL } from "blob-util"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import * as React from "react"
import { FC, useEffect, useRef, useState } from "react"
import { Line } from "react-chartjs-2"
import theme from "../../../App.theme"
import { capitzalize, numberParser, numberWithCommas } from "../../../helpers/helperFunctions"

import useStyles from "./styles"

const FONT_SIZE: number = 14

interface IHistoryChart {
  results: any
}

const HistoryChart: FC<IHistoryChart> = ({ results }) => {
  const styles = useStyles()
  const chartRef = useRef(null)
  const [urlImgBase, setUrlImgBase] = useState("")

  const breakpoint = useBreakpoint()

  const { _id, ...resultData } = results[0]
  const resultKeys = Object.keys(resultData)

  const background = [
    theme.palette.extras.yellow,
    theme.palette.extras.olive,
    theme.palette.extras.orange,
    theme.palette.extras.darkOlive,
    theme.palette.extras.darkBlue,
    theme.palette.extras.blueType2,
    theme.palette.extras.blueType1
  ]


  const data = {
    datasets: resultKeys.map((key, i) => ({
      label: capitzalize(key).split("_").join(" "),
      type: "line",
      data: [...results.map(result => numberParser(result[key]).value)],
      fill: true,
      backgroundColor: background[i],
      yAxisID: i === 0 && "y-axis-1"
    }))
  }


  const options = {
    type: "line",
    animation: false,
    responsive: true,
    legend: {
      display: true,
      labels: {
        fontSize: FONT_SIZE
      }
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex]
          const currentValue = dataset.data[tooltipItem.index]
          return numberWithCommas(currentValue)
        },
        title: function(tooltipItem, data) {
          const label = tooltipItem[0]
          return `${ data.datasets[label.datasetIndex].label } ${ label.label }`
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontSize: FONT_SIZE

          },
          display: true,
          gridLines: {
            display: false
          },

          labels: [
            ...results.map(e => e._id)
          ],
          scaleLabel:{
            display: true,
            labelString: 'Periodos'
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontSize: FONT_SIZE,
            callback: function(value) {
              return numberWithCommas(value)
            }
          },

          display: true,
          position: "right",
          id: "y-axis-1",
          gridLines: {
            display: true
          },
          labels: {
            show: true
          },
          scaleLabel:{
            display: true,
            labelString: 'Resultado'
          }
        }
      ]
    }

  }


  useEffect(() => {
    if (chartRef) {
      const base64Image = chartRef?.current?.chartInstance.toBase64Image()
      const blob = base64StringToBlob(base64Image?.split(",")[1], "image/jpeg")
      const blobURL = createObjectURL(blob)

      setUrlImgBase(blobURL)
    }
  }, [chartRef])

  return (
    <Grid container className={ styles.root }>
      <Grid item xs className={styles.chartContainer}>
        <Line
          ref={ chartRef }
          width={ breakpoint.sm ? 280 : 600 }
          height={ breakpoint.sm ? 280 : 600 }
          { ...{ data, options } }
        />
      </Grid>

      <Grid item xs container className={styles.downloadContainer}>
        <a
          href={ urlImgBase }
          target="_blank"
          download
          aria-label="Descargar gráfica"
          className={ styles.downloadButton }
        >
          Descargar gráfica
        </a>
      </Grid>
    </Grid>
  )
}

export default HistoryChart
