import { Box, Grid } from "@material-ui/core"
import * as React from "react"
import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useRef, useState } from "react"
import { BsInfoCircle, BsTable } from "react-icons/bs"
import { FaFileExport } from "react-icons/fa"
import { ExportTab, InformationTab } from ".."
import { IOpendataHook } from "../../../hooks/useOpendata"
import { Button } from "../../_common"
import { DataTable } from "../../_dataMgmt"

import { SectionTitle, Tabs } from "../../_layout"

import useStyles from "./styles"

interface IDataTabs extends Partial<IOpendataHook> {
  titleClick: (i: number) => void
  value: number
  setValue: Dispatch<SetStateAction<number>>
  informationTabContent: string
  extraInfoComponent: ReactNode
  title: string
  exportAction: any
  withPagination?: boolean
  nullText?: string
  withChart?: boolean
  chartAction?: () => void
}

const DataTabs: FC<IDataTabs> = ({
                                   pagina,
                                   numPag,
                                   numReg,
                                   paginator,
                                   registrosPorPagina,
                                   loadingDatosAbiertos,
                                   setPage,
                                   datosAbiertos,
                                   listTitles,
                                   colProps,
                                   chips,
                                   titleClick,
                                   orden,
                                   isSearchAvailable,
                                   value,
                                   setValue,
                                   informationTabContent,
                                   extraInfoComponent,
                                   title,
                                   exportAction,
                                   withPagination,
                                   nullText,
                                   withChart,
                                   chartAction
                                 }) => {
  const [height, setHeight] = useState(100)
  const wrapperRef = useRef(null)
  const styles = useStyles()

  useEffect(() => {
    if (
      wrapperRef.current &&
      wrapperRef.current.getBoundingClientRect().height
    ) {
      setHeight(wrapperRef.current.getBoundingClientRect().height)
    }
  }, [])

  return (
    <Grid item xs>
      <Box mx={ 4 } my={ 2 }>
        <SectionTitle { ...{ title } } />
      </Box>
      <Tabs
        { ...{ value, setValue } }
        tabs={ [
          {
            title: "Información",
            component: (
              <InformationTab
                content={ informationTabContent }
                { ...{ extraInfoComponent } }
              />
            ),
            icon: <BsInfoCircle />,
            renders: true
          },
          {
            title: "Tabla",
            component: (
              <Grid container>

                <DataTable
                  ref={ wrapperRef }
                  paginate={ withPagination }
                  loader={ loadingDatosAbiertos }
                  items={ datosAbiertos }
                  trueHeight={ height < 300 ? 300 : height }
                  config={ {
                    listTitles,
                    colProps,
                    chips,
                    idProp: "_id",
                    rowHeight: 44,
                    pagina,
                    numPag,
                    numReg,
                    paginator,
                    registrosPorPagina,
                    paginationAction: setPage,
                    nullText
                  } }
                  { ...{ titleClick, orden } }
                />
                { withChart && <Grid item container justify={ "flex-end" } className={ styles.graphBtnContainer }>
                  <Button
                    small
                    aria="Ver gráfica de resultados"
                    handleClick={ chartAction }
                  >
                    Ver gráfica de resultados
                  </Button>
                </Grid> }
              </Grid>
            ),
            icon: <BsTable />,
            renders: true
          },
          {
            title: "Exportar consulta",
            component: <ExportTab { ...{ exportAction, isSearchAvailable } } />,
            icon: <FaFileExport />,
            renders: true
          }
        ] }
      />
    </Grid>
  )
}

export default DataTabs
