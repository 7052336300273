import * as React from "react"
import { Grid, Typography } from "@material-ui/core"

import { FC, ReactNode, useContext, useState } from "react"
import { Context as ModalsContext, IModalContext } from "../../../context/ModalContext"
import { PageHeader, Tabs } from "../../_layout"

import { HiOutlineDatabase } from "react-icons/hi"
import { VideoSection } from "../../homepage"

import useStyles from "./styles"


interface IGeneralTabs {
  opendataTabs: {
    title: string
    component: ReactNode
    icon: any
  }[]
}

const GeneralTabs: FC<IGeneralTabs> = ({ opendataTabs }) => {
  const styles = useStyles()

  const [value, setValue] = useState(0)
  const { openModal } = useContext<IModalContext>(ModalsContext)

  const tabs = opendataTabs.map((e) => ({
    title: e.title,
    component: e.component,
    icon: e.icon,
    renders: true,
    tooltip: e.title
  }))

  const handleVideoInstructions = () => {
    openModal("",
      <Grid className={ styles.videoModalWrapper }>
        <VideoSection videoYoutubeID="hWRzWQrzcc4" />
      </Grid>)
  }

  return (
    <Grid item xs className={ styles.root }>
      <PageHeader
        title={"Consulta los datos de inclusión financiera"}
        icon={ <HiOutlineDatabase /> }
      />
      <Tabs { ...{ value, setValue, tabs } } tabsText="1. Selecciona el tipo de datos que deseas consultar." dark />
    </Grid>
  )
}

export default GeneralTabs
