import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0),
      margin: theme.spacing(4, 0),
    },
  },

  content: {
    margin: theme.spacing(2, 0, 4, 0),
  },

  item: {
    padding: theme.spacing(4, 0),
    "& span": {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
    "& p": {
      margin: theme.spacing(1, 0),
    },
  },

  info: {
    margin: theme.spacing(1, 0),
    "& span": {
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.disabled,
    },
  },

  buttonContainer: {
    margin: theme.spacing(1, 0),
  },

  h6: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))
