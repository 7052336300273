// noinspection DuplicatedCode

import { Grid } from "@material-ui/core"

import { base64StringToBlob, createObjectURL } from "blob-util"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import * as React from "react"
import { FC, useEffect, useRef, useState } from "react"
import { HorizontalBar, Line } from "react-chartjs-2"
import theme from "../../../App.theme"
import { capitzalize, numberParser } from "../../../helpers/helperFunctions"

import useStyles from "./styles"

const FONT_SIZE: number = 14

interface IENIFChart {
  results: any
}

const ENIFChart: FC<IENIFChart> = ({ results }) => {
  const styles = useStyles()
  const chartRef = useRef(null)
  const [urlImgBase, setUrlImgBase] = useState("")

  const breakpoint = useBreakpoint()

  const { _id, desagregacion, poblacion, variable, ...resultData } = results[0]
  const resultKeys = Object.keys(resultData)

  const background = [
    theme.palette.extras.blueType1
  ]

  const data = {
    labels: results.map(e => e.desagregacion),
    datasets: resultKeys.map((key, i) => ({
      label: capitzalize(key).split("_").join(" "),
      data: [...results.map(result => numberParser(result[key]).value)],
      fill: false,
      backgroundColor: background[i]
    }))
  }


  const config = {
    type: "bar",
    data: data,
    options: {
      responsive: true,
      legend: {
        display: true,
        labels: {
          fontSize: FONT_SIZE
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontSize: FONT_SIZE
            },
            scaleLabel:{
              display: true,
              labelString: 'Desagregación'
            }

          }
        ],
        xAxes: [
          {
            ticks: {
              fontSize: FONT_SIZE,
              callback: function(value, index, values) {
                return `${ value }%`
              }

            }            ,
            scaleLabel:{
              display: true,
              labelString: 'Resultado'
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex]
            const currentValue = dataset.data[tooltipItem.index]
            return `${ parseFloat(currentValue.toFixed(1)) }%`
          },
          title: function(tooltipItem) {
            return tooltipItem[0].label
          }
        }
      }
    }
  }

  const handleDownload = () => {
    if (chartRef) {
      let canvas = document.getElementsByClassName("chartjs-render-monitor")

      // @ts-ignore
      let url = canvas[0].toDataURL("image/png");
      const a = document.createElement("a")
      a.download = 'enif.png';
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }

  return (
    <Grid container className={ styles.root }>
      <Grid item xs className={styles.chartContainer}>
        <HorizontalBar
          ref={ chartRef }
          width={ breakpoint.sm ? 280 : 600 }
          height={ breakpoint.sm ? 280 : 600 }
          { ...config }
        />
      </Grid>

      <Grid item xs container className={styles.downloadContainer}>
        <a
          href={ urlImgBase }
          target="_blank"
          onClick={handleDownload}
          aria-label="Descargar gráfica"
          className={ styles.downloadButton }
        >
          Descargar gráfica
        </a>
      </Grid>
    </Grid>
  )
}

export default ENIFChart
