import * as React from "react"
import { FC, useContext, useRef, useState } from "react"
import { DataControls, DataTabs, DBDictionary, DBInformation, HistoryChart } from ".."
import { Context as ModalsContext, IModalContext } from "../../../context/ModalContext"
import { normalize } from "../../../helpers/helperFunctions"
import { useOpendataHistory } from "../../../hooks"
import { PageNavigatorLayout } from "../../_layout"
import { IDCControl } from "../DataControls/DataControls"

interface IDataIndex {
  content: string
}

const DataIndexHistory: FC<IDataIndex> = ({ content }) => {
  const { openModal } = useContext<IModalContext>(ModalsContext)
  const [tabValue, setTabValue] = useState(0)
  const variablesRef = useRef(null)
  const periodosRef = useRef(null)

  const {
    // Variables
    loadingDatosAbiertos,
    isSearchAvailable,
    ordenParam: orden,
    setOrdenarPorParam: setOrdenarPor,
    loadingVariable,
    categoriaIndex,
    sectorIndex,

    // Getters
    fetchOpendataHistory,

    // Setters
    setDescripcionesBuscadas,
    setPeriodosBuscados,
    setCategoriaIndex,
    setSectorIndex,

    // Helpers
    resetFilters,
    exportSearch,

    // Data table
    listTitles,
    colProps,
    chips,
    handleSortByColumnIndex,

    // Context state
    datosAbiertos,
    metadata,
    categorias,
    sectores,
    descripciones,
    periodos,

    // Context methods
    getOpendataMeta,
    getDownloadURL,
    resetDatosAbiertos
  } = useOpendataHistory()

  const handleResetControls = () => {
    variablesRef?.current.resetSelectedValues()
    periodosRef?.current.resetSelectedValues()
    resetFilters()
  }

  const handleSectoresValueChange = (v: string) => {
    setSectorIndex(sectores.findIndex(e => e === v))
    variablesRef?.current?.resetSelectedValues()
    periodosRef?.current?.resetSelectedValues()
    setDescripcionesBuscadas(null)
    setPeriodosBuscados(null)
    resetDatosAbiertos()
  }

  const handleChart = () => {
    openModal("Gráfica de resultados", <HistoryChart results={ datosAbiertos } />)
  }


  const controls: IDCControl[] = [
    {
      type: "radio",
      title: "Categorías",
      options: normalize(categorias),
      value: typeof categoriaIndex === "number" && categorias && categorias[categoriaIndex],
      valueSetter: (v: string) =>
        setCategoriaIndex(categorias.findIndex(e => e === v)),
      renders: Boolean(categorias)
    },
    {
      type: "radio",
      title: "Sectores",
      options: normalize(sectores),
      value: typeof sectorIndex === "number" && sectores && sectores[sectorIndex],
      valueSetter: handleSectoresValueChange,
      renders: Boolean(sectores && categoriaIndex !== null)
    },
    {
      ref: variablesRef,
      type: "multiChips",
      id: "variablesSelect",
      title: "Variables (max. 3)",
      selectionLimit: 3,
      placeholder: "Seleccionar variables",
      options: normalize(descripciones),
      onSelect: updatedList =>
        setDescripcionesBuscadas(updatedList.map(e => e.value)),
      onRemove: updatedList =>
        setDescripcionesBuscadas(updatedList.map(e => e.value)),
      renders: Boolean(descripciones && sectorIndex !== null)
    },
    {
      ref: periodosRef,
      type: "multiChips",
      id: "periodosSelect",
      title: "Periodos (max. 12)",
      selectionLimit: 12,
      placeholder: "Seleccionar periodos",
      options: normalize(periodos).sort((a,
                                         b) => (a.value < b.value) ? 1 : -1),
      onSelect: updatedList =>
        setPeriodosBuscados(updatedList.map(e => e.value)),
      onRemove: updatedList =>
        setPeriodosBuscados(updatedList.map(e => e.value)),
      renders: Boolean(periodos && sectorIndex !== null)
    }
  ]


  return (
    <PageNavigatorLayout
      navigatorComponent={
        <DataControls
          handleReset={ handleResetControls }
          fetch={ fetchOpendataHistory }
          { ...{
            // Getters
            setTabValue,
            loadingVariable,
            isSearchAvailable,
            //Helpers
            resetFilters,
            // Props
            controls
          } }
        />
      }
      detailComponent={
        <DataTabs
          withChart={ isSearchAvailable }
          chartAction={ handleChart }
          title="Datos históricos administrativos de inclusión financiera"
          nullText="N/D"
          exportAction={ exportSearch }
          titleClick={ handleSortByColumnIndex }
          value={ tabValue }
          setValue={ setTabValue }
          informationTabContent={ content }
          extraInfoComponent={
            <>
              <DBInformation
                { ...{ metadata } }
                fetchMetadata={ () => getOpendataMeta("historico") }
                downloadDBAction={ () => getDownloadURL("historico") }
              />
              <DBDictionary />
            </>
          }
          { ...{
            loadingDatosAbiertos,
            datosAbiertos,
            listTitles,
            colProps,
            chips,
            setOrdenarPor,
            orden,
            isSearchAvailable
          } }
        />
      }
    />
  )
}

export default DataIndexHistory


