import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

export default makeStyles((theme: ITheme) => ({
  root: {},

  chartContainer: {},

  downloadContainer: {
    padding: theme.spacing(2),
    alignItems: "center",
    justifyContent: 'center',
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
    }
  },

  downloadButton: {
    padding: theme.spacing(0.5, 0.75),
    border: `1px ${ theme.palette.text.primary } solid`,
    borderRadius: theme.roundness,

    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,

    lineHeight: theme.lineHeight(1.75),
    letterSpacing: theme.letterspacing(0.3),
    transition: theme.palette.extras.transitions.default,
    textDecoration: "none",

    "&:hover": {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.primary.main
    }
  }
}))
