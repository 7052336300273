import { Grid, Typography } from "@material-ui/core"
import * as React from "react"
import { FC, ReactNode } from "react"

import useStyles from "./styles"


interface IInformationTab {
  content: string
  extraInfoComponent: ReactNode
}

const InformationTab: FC<IInformationTab> = ({
                                               content,
                                               extraInfoComponent
                                             }) => {
  const styles = useStyles()

  return (
    <Grid container direction="column">
      <Grid container className={ styles.content }>
        <Typography component="p">{ content }</Typography>
      </Grid>
      { extraInfoComponent }
    </Grid>
  )
}

export default InformationTab
