import { Grid, Typography } from "@material-ui/core"
import * as React from "react"
import { Dispatch, FC, SetStateAction } from "react"
import theme from "../../../App.theme"
import { IOpendataHistoryHook } from "../../../hooks/useOpendataHistory"
import { Button } from "../../_common"

import useStyles from "./styles"

interface IDataControlsHistory extends Partial<IOpendataHistoryHook> {
  setTabValue: Dispatch<SetStateAction<number>>
  fetch: () => Promise<void>
}

const DataControlsHistory: FC<IDataControlsHistory> = ({
                                                         fetch,
                                                         setTabValue,
                                                         isSearchAvailable
                                                       }) => {
  const styles = useStyles()

  const handleGoToTop = () =>
    typeof window !== "undefined" &&
    window.scrollTo({ top: 0, behavior: "smooth" })

  return (
    <Grid
      item
      container
      justify="flex-end"
      style={ { padding: theme.spacing(2) } }
    >
      <Button
        aria="Generar consulta"
        small
        light
        disabled={ !isSearchAvailable }
        handleClick={ () => {
          setTabValue(1)
          // handleGoToTop()
          fetch()
        } }
      >
        <Typography component="span">Generar consulta</Typography>
      </Button>
    </Grid>
  )
}

export default DataControlsHistory
