import { Checkbox, FormControlLabel, FormGroup, Grid } from "@material-ui/core"
import * as React from "react"
import { FC, useState } from "react"

import { capitzalize } from "../../../helpers/helperFunctions"

import { Accordion } from "../../_common"
import useStyles from "./styles"

interface ICategoryChecklist {
  options: string[]
  title: string
  selectionCallback:
    | ((checkedList: string[]) => void)
    | ((checkedList: string[]) => Promise<void>)
}

const CategoryChecklist: FC<ICategoryChecklist> = ({
                                                     title,
                                                     options,
                                                     selectionCallback
                                                   }) => {
  const styles = useStyles()
  const [checkboxesRef, setCheckboxesRef] = useState([])
  const arrLength = options.length

  React.useEffect(() => {
    // add or remove refs
    setCheckboxesRef(objetivosRefs =>
      Array(arrLength)
      .fill("")
      .map((_, i) => checkboxesRef[i] || React.createRef())
    )
  }, [arrLength])

  const handleCheckboxChange = () => {
    const checkedCheckboxes = checkboxesRef
    .filter(e => e.current.checked)
    .map(e => e.current.name)

    selectionCallback(checkedCheckboxes)
  }

  return (
    <Grid container>
      <Accordion
        { ...{ title } }
        content={
          <FormGroup className={ styles.controlItems }>
            { options.map((e, i) => {
              return (
                <Grid key={ i } item container className={ styles.itemContainer }>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          checkboxesRef[i] && checkboxesRef[i].current
                            ? checkboxesRef[i].current.checked
                            : false
                        }
                        classes={ { root: styles.checkbox } }
                        inputRef={ checkboxesRef[i] }
                        onChange={ handleCheckboxChange }
                        name={ e }
                        color="default"
                      />
                    }
                    label={ capitzalize(e.split("_").join(" ")) }
                  />
                </Grid>
              )
            }) }
          </FormGroup>
        }
      />
    </Grid>
  )
}

export default CategoryChecklist
