import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {},
  },

  controlItems: {
    color: theme.palette.text.lightText,
    "& span": {
      fontSize: theme.typography.body2.fontSize,
    },
  },

  itemContainer: {
    margin: theme.spacing(1, 0),
  },

  checkbox: {
    color: theme.palette.text.lightText,
  },

  hidden: {
    display: "none",
  },
}))
