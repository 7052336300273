import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
      flexDirection: "column",
    },
  },

  titleWrapper: {
    margin: theme.spacing(2, 0),
    "& span": {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.body2.fontSize,
    },
  },

  itemWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& ul": {
      width: "45%",
      listStyleType: "none",
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
  },

  formatWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& span": {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.body2.fontSize,
    },
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
}))
