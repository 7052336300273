import { Box, Button, Grid, Typography } from "@material-ui/core"
import * as React from "react"
import { useMemo } from "react"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"
import useStyles from "./styles"

interface IPaginationOptions {
  pagina: number
  numPag: number
  numReg: number
  registrosPorPagina: number
  paginator: () => (string | number)[]
}

interface IPaginationProps {
  options: IPaginationOptions
  fetchData: (page: number) => void
}

const Pagination: React.FC<IPaginationProps> = ({ options, fetchData }) => {
  const { pagina, numPag, numReg, paginator, registrosPorPagina } = options
  const {
    paginationButtonActive,
    paginationButtonNotActive,
    paginationWrapper,
    paginationButtonArrow,
    textClass
  } = useStyles()

  const getPage = (e: string | number): number => {
    if (typeof e === "string") {
      if (e === "prev") {
        return pagina - 1
      } else if (e === "next") {
        return pagina + 1
      }
    } else {
      return e
    }
  }

  const showingElements = useMemo(
    () => (numReg < registrosPorPagina ? numReg : registrosPorPagina),
    [numReg, registrosPorPagina]
  )

  return (
    <Grid container direction="column" id="pagination">
      <Grid item xs container justify="flex-end">
        <Typography variant="body2" className={ textClass }>
          Página <strong>{ pagina }</strong> de <strong>{ numPag }</strong>.
          Mostrando <strong>{ showingElements }</strong> de{ " " }
          <strong>{ numReg }</strong> elementos.
        </Typography>
      </Grid>
      <Grid item xs container justify="flex-end" className={ paginationWrapper }>
        { paginator().map((e: string | number, i: number) => (
          <Button
            aria-label={ "pagina " + (i + 1) }
            key={ i }
            onClick={ () => {
              fetchData(getPage(e))
              typeof window !== "undefined" &&
              window.scrollTo({ top: 0, behavior: "smooth" })
            } }
            classes={ {
              root:
                typeof e === "string"
                  ? paginationButtonArrow
                  : pagina === e
                  ? paginationButtonActive
                  : paginationButtonNotActive
            } }
          >
            { e === "prev" ? (
              <Box display="flex" alignItems="center">
                <BsChevronLeft fontSize="small" />
              </Box>
            ) : e === "next" ? (
              <Box display="flex" alignItems="center">
                <BsChevronRight fontSize="small" />
              </Box>
            ) : (
              e
            ) }
          </Button>
        )) }
      </Grid>
    </Grid>
  )
}

export default Pagination
