import { FC } from "react"
import * as React from "react"
import { Grid, Typography } from "@material-ui/core"

import useStyles from "./styles"
import { RiFilterOffLine } from "react-icons/ri"
import { IOpendataHistoryHook } from "../../../hooks/useOpendataHistory"

interface IHeader extends Partial<IOpendataHistoryHook> {
  handleReset?: () => void
}

const Header: FC<IHeader> = ({ handleReset }) => {
  const styles = useStyles()

  return (
    <Grid item container className={ styles.helpContainer }>
      <Grid item>
        <Typography>
          2. Utiliza los controles para personalizar tu consulta
        </Typography>
      </Grid>
      {
        handleReset && <Grid
          item
          container
          className={ styles.cleanFilters }
          onClick={ handleReset }
        >
          <RiFilterOffLine />
          <Typography component="span">Limpiar filtros</Typography>
        </Grid>
      }
    </Grid>
  )
}

export default Header
