export default (itemToCheck: Object | string, index: number, arr: string[]) => {
  if (typeof itemToCheck !== "object") {
    const checker = itemToCheck.split(".", 2)

    if (checker.length === 2) {
      return arr[index][checker[0]][checker[1]]
    } else if (checker.length === 1) {
      return arr[index][checker[0]]
    }
  }
}
