import * as React from "react"
import { FC, useState } from "react"
import { DataControls, DBDictionary, DBInformation } from ".."
import { normalize } from "../../../helpers/helperFunctions"
import { useOpendata } from "../../../hooks"

import { PageNavigatorLayout } from "../../_layout"
import { IDCControl } from "../DataControls/DataControls"
import DataTabs from "../DataTabs/DataTabs"

interface IDataIndex {
  content: string
}

const DataIndex: FC<IDataIndex> = ({ content }) => {
  const searchParamsList = [
    { id: 3, field: "Población adulta", colProp: "pob_adulta" },
    { id: 4, field: "Por cada 10 mil habitantes", colProp: "c_10_mil" }
  ]

  const [tabValue, setTabValue] = useState(0)

  const {
    pagina,
    paginator,
    numPag,
    numReg,
    registrosPorPagina,
    loadingDatosAbiertos,
    setPage,
    datosAbiertos,
    fetchOpendata,
    setElementsPP,
    resetFilters,
    listTitles,
    colProps,
    chips,
    categorias,
    categoriaIndex,
    setCategoriaIndex,
    sectores,
    sectorIndex,
    setSectorIndex,
    descripciones,
    descripcionIndex,
    setDescripcionIndex,
    tipo,
    setTipo,
    orden,
    elementsPP,
    loadingVariable,
    setOrdenarPor,
    handleSortByColumnIndex,
    fetchOpendataFull,
    isSearchAvailable,
    metadata,
    getOpendataMeta,
    getDownloadURL
  } = useOpendata({ searchParamsList })

  const controls: IDCControl[] = [
    {
      type: "radio",
      title: "Categorías",
      options: normalize(categorias),
      value: typeof categoriaIndex === "number" && categorias[categoriaIndex],
      valueSetter: (v: string) =>
        setCategoriaIndex(categorias.findIndex(e => e === v)),
      renders: Boolean(categorias)
    },
    {
      type: "radio",
      title: "Sectores",
      options: normalize(sectores),
      value: typeof sectorIndex === "number" && sectores[sectorIndex],
      valueSetter: v => {
        setSectorIndex(sectores.findIndex(e => e === v))
      },
      renders: Boolean(sectores && categoriaIndex !== null)
    },
    {
      type: "radio",
      title: "Variables",
      options: normalize(descripciones),
      value: typeof descripcionIndex === "number" && descripciones && descripciones[descripcionIndex],
      valueSetter: v => {
        setDescripcionIndex(descripciones.findIndex(e => e === v))
      },
      renders: Boolean(descripciones && sectorIndex !== null)
    },
    {
      type: "radio",
      title: "Agrupar",
      options: [
        { value: "municipios", label: "Municipio" },
        { value: "estados", label: "Estado" },
        { value: "nacional", label: "Nacional" }
      ],
      value: tipo,
      valueSetter: setTipo,
      renders: Boolean(descripcionIndex !== null)
    },
    {
      type: "radio",
      title: "Elementos por página",
      options: [
        { value: "5", label: "5" },
        { value: "10", label: "10" },
        { value: "25", label: "25" },
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "150", label: "150" },
        { value: "250", label: "250" },
        { value: "10000", label: "Todos" },
      ],
      value: elementsPP,
      valueSetter: setElementsPP,
      renders: Boolean(descripcionIndex !== null)
    }

  ]

  return (
    <PageNavigatorLayout
      navigatorComponent={
        <DataControls
          handleReset={ resetFilters }
          fetch={ () => fetchOpendata({
            pagina: 1,
            cantidadRegistros: registrosPorPagina
          }) }
          { ...{
            // Getterss
            setTabValue,
            loadingVariable,
            isSearchAvailable,
            //Helpers
            resetFilters,
            // Props
            controls
          } }
        />
      }
      detailComponent={
        <DataTabs
          withPagination
          title="Datos administrativos de inclusión financiera"
          exportAction={ fetchOpendataFull }
          titleClick={ handleSortByColumnIndex }
          value={ tabValue }
          setValue={ setTabValue }
          informationTabContent={ content }
          extraInfoComponent={
            <>
              <DBInformation
                { ...{ metadata } }
                fetchMetadata={ () => getOpendataMeta("opendata") }
                downloadDBAction={ () => getDownloadURL("opendata") }
              />
              <DBDictionary />
            </>
          }
          { ...{
            pagina,
            numPag,
            numReg,
            paginator,
            registrosPorPagina,
            setPage,
            loadingDatosAbiertos,
            datosAbiertos,
            listTitles,
            colProps,
            chips,
            setOrdenarPor,
            orden,
            isSearchAvailable
          } }
        />
      }
    />
  )
}

export default DataIndex
