import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {},
  },

  radioLabel: {
    margin: theme.spacing(2,0),
    color: theme.palette.text.lightText,
    fontSize: theme.typography.body2.fontSize,
  },

  radio: {
    color: theme.palette.text.lightText,
  },

  radioChecked: {
    color: theme.palette.text.lightText,
  },
}))
