import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import { Context as DAContext, IDatosAbiertosContextType } from "../../../context/DatosAbiertosContext"
import { Context as ModalsContext, IModalContext } from "../../../context/ModalContext"
import { downloadCSV, normalize } from "../../../helpers/helperFunctions"
import { useHooksHelpers } from "../../../hooks"
import ENIFControls, { Orden, OrdenParam } from "../../../lib/ENIFControls"
import { PageNavigatorLayout } from "../../_layout"
import { IDCControl } from "../DataControls/DataControls"
import { DataControls, DataTabs, DBDictionary, DBInformation, ENIFChart } from "../index"

interface IDataIndexENIF {
  content: string
}

const {
  search,
  fetchVariables,
  reset,
  getSelectedParams,
  getState,
  setCategoriaIndex: setCat,
  setSeccionIndex: setSec,
  setPreguntaIndex: setPreg,
  setOrdenarPor,
  setOrden
} = ENIFControls

interface ISearchParams {
  id: number
  field: string
  colProp: OrdenParam
}

const DataIndexENIF: FC<IDataIndexENIF> = ({ content }) => {

  const {
    state: { metadata },
    getOpendataMeta,
    getDownloadURL
  } = useContext<IDatosAbiertosContextType>(DAContext)

  const searchParamsList: ISearchParams[] = [
    { id: 0, field: "Categoría", colProp: "desagregacion" },
    { id: 1, field: "Población", colProp: "variable" },
    { id: 2, field: "% de la población", colProp: "porcentaje_poblacion" }
  ]

  const [tabValue, setTabValue] = useState(0)

  const { listTitles, colProps, chips } = useHooksHelpers({
    searchParamsList
  })

  const [secciones, setSecciones] = useState(null)
  const [seccionIndex, setSeccionIndex] = useState(getSelectedParams().seccionIndex)

  const [preguntas, setPreguntas] = useState(null)
  const [preguntaIndex, setPreguntaIndex] = useState(getSelectedParams().preguntaIndex)

  const [categorias, setCategorias] = useState(null)
  const [categoriaIndex, setCategoriaIndex] = useState(getSelectedParams().categoriaIndex)

  // TODO change to effect search availability
  const [isSearchAvailable, setIsSearchAvailable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingVariables, setLoadingVariables] = useState(false)

  const { openModal } = useContext<IModalContext>(ModalsContext)


  const [resultados, setResultados] = useState(getState().resultados)

  useEffect(() => {
    if (!secciones && !preguntas && !categorias) {
      initSearchOptions()
    }
  }, [])

  const resetSearch = async () => {
    reset()
    setSecciones(null)
    setSeccionIndex(null)
    setPreguntas(null)
    setPreguntaIndex(null)
    setCategorias(null)
    setCategoriaIndex(null)
    setIsSearchAvailable(false)
    setResultados(getState().resultados)
    await initSearchOptions()
  }

  const initSearchOptions = async () => {
    reset()
    setLoadingVariables(true)
    const variables = await fetchVariables()

    if (variables) {
      setSecciones(variables.secciones)
      setCategorias(variables.categorias.map(e => e.categoria).sort())
    }

    setLoadingVariables(false)
  }


  const handleOrden = async (col: number) => {
    const { orden } = getState()
    setOrden(-orden as Orden)
    setOrdenarPor(searchParamsList[col].colProp)

    await handleConsultarResultados()
  }

  const handleConsultarResultados = async () => {
    setLoading(true)
    const data = await search()
    setResultados(data)
    setLoading(false)
  }

  const handleExportSearch = async () => {
    await downloadCSV(resultados.map(e => ({
      categoria: e.desagregacion,
      poblacion: e.variable,
      porcentaje_poblacion: e.porcentaje_poblacion
    })), "consulta-enif")
  }

  const controlConfig: IDCControl[] = [
    {
      type: "radio",
      title: "Secciones",
      options: normalize(secciones),
      value: secciones && typeof seccionIndex === "number" && secciones[seccionIndex],
      valueSetter: (v: string) => {
        const index = secciones.findIndex(e => e === v)
        setSeccionIndex(index)
        const preguntasSeccion = setSec(index)
        setPreguntas(preguntasSeccion)
      },
      renders: Boolean(secciones)
    },
    {
      type: "radio",
      title: "Preguntas",
      options: normalize(preguntas),
      value: preguntas && typeof preguntaIndex === "number" && preguntas[preguntaIndex],
      valueSetter: (v: string) => {
        const index = preguntas.findIndex(e => e === v)
        setPreguntaIndex(index)
        setPreg(index)
      },
      renders: Boolean(preguntas)
    },
    {
      type: "radio",
      title: "Categorías",
      options: normalize(categorias),
      value: categorias && typeof categoriaIndex === "number" && categorias[categoriaIndex],
      valueSetter: (v: string) => {
        const index = categorias.findIndex(e => e === v)
        setCategoriaIndex(index)
        setCat(index)
        setIsSearchAvailable(true)
      },
      renders: Boolean(preguntas && categorias)
    }
  ]


  const handleChart = () => {
    openModal("Gráfica de resultados",
      <ENIFChart results={ resultados } />)
  }


  return (
    <PageNavigatorLayout
      navigatorComponent={
        <DataControls
          controls={ controlConfig }
          handleReset={ resetSearch }
          fetch={ handleConsultarResultados }
          loadingVariable={ loadingVariables }
          isSearchAvailable={ true }
          { ...{ setTabValue } }
        />
      }
      detailComponent={
        <DataTabs
          withChart={ isSearchAvailable }
          chartAction={ handleChart }
          title="Encuesta Nacional de Inclusión Financiera"
          nullText="N/D"
          exportAction={ handleExportSearch }
          titleClick={ handleOrden }
          value={ tabValue }
          setValue={ setTabValue }
          informationTabContent={ content }
          loadingDatosAbiertos={ loading }
          datosAbiertos={ resultados }
          orden={ Boolean(getState().orden < 0) }
          extraInfoComponent={
            <>
              <DBInformation
                { ...{ metadata } }
                fetchMetadata={ () => getOpendataMeta("enif") }
                downloadDBAction={ () => getDownloadURL("enif") }
              />
              <DBDictionary />
            </>
          }
          { ...{
            listTitles,
            colProps,
            chips,
            isSearchAvailable
          } }
        />
      }
    />
  )
}

export default DataIndexENIF

