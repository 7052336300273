import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  infoWrapper: {
    "& h6": {
      margin: theme.spacing(0, 0.5),
      fontWeight: theme.typography.fontWeightBold,

      color: theme.palette.text.disabled,
    },
  },

  infoItem: {
    margin: theme.spacing(1, 0),

    "& span": {
      fontSize: theme.typography.body2.fontSize,
    },
  },

  infoItemCell: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0.5),
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: theme.roundness,
    alignItems: "center",
  },

  infoItemCellTitle: {
    "& span": {
      fontWeight: theme.typography.fontWeightBold
    },

  },

  dictionaryWrapper: {},
}))
