import { Chip } from "@material-ui/core"

import { Grid, ListItem, ListItemText } from "@material-ui/core/"
import * as React from "react"
import { FC, Fragment, useMemo } from "react"
import { Object } from "../../../helpers/Types"

import nestedChecker from "./nestedChecker"
import separadorMiles from "./separadorMiles"
import useStyles from "./styles"

export interface IRowAction {
  title: string
  action: (id: number, index: number) => void
  disabled?: boolean
}

export interface IDataRow {
  items: any
  colProps: string[]
  chips: string[]
  handleRowClick: (id: string, index: number) => void
  rowActions: IRowAction[]
  idProp: string
  nullText?: string
}

export interface IRow {
  index: number
  style: Object
  data: IDataRow
}

const Row: FC<IRow> = ({ index, style, data }) => {
  const {
    items,
    colProps,
    chips = [],
    handleRowClick,
    rowActions,
    idProp,
    nullText
  } = data

  const classes = useStyles({})

  const id = useMemo(() => nestedChecker(idProp, index, items), [
    idProp,
    index,
    items
  ])

  return (
    <div>
      { items[index] && (
        <ListItem
          // button={false}
          style={ style }
          classes={ { root: index % 2 === 0 ? classes.listItemRow : '' } }
          key={ index }
          onClick={ () =>
            !rowActions && handleRowClick ? handleRowClick(id, index) : null
          }
        >
          <Grid container>
            { colProps.map((col, i) => {
              const receivedContent = nestedChecker(col, index, items)
              return (
                <Grid item xs key={ `${ i }${ col }` } className={ classes.item }>
                  { chips[i] ? (
                    <Chip
                      classes={ {
                        root: chips[i]["class"],
                        disabled: chips[i]["disabledClass"]
                      } }
                      color="primary"
                      size="small"
                      icon={ chips[i]["icon"] }
                      label={ receivedContent }
                      disabled={ receivedContent === 0 }
                    />
                  ) : typeof receivedContent === "string" ||
                  typeof receivedContent === "number" ? (
                    <ListItemText
                      classes={ { root: classes.item, primary: classes.text } }
                      primary={ separadorMiles(receivedContent) }
                      primaryTypographyProps={ { noWrap: true } }
                    />
                  ) : receivedContent === null ? (
                    <ListItemText
                      classes={ { primary: classes.text } }
                      primary={ nullText || "-" }
                    />
                  ) : typeof receivedContent === "boolean" ? (
                    <ListItemText
                      classes={ { root: classes.item, primary: classes.text } }
                      primary={
                        <div
                          className={
                            receivedContent
                              ? classes.onStatus
                              : classes.offStatus
                          }
                        />
                      }
                      primaryTypographyProps={ { noWrap: true } }
                    />
                  ) : (
                    <span>{ col }</span>
                  ) }
                </Grid>
              )
            }) }
            { rowActions && (
              <Grid item xs className={ classes.item }>
                { rowActions.map(({ title, action, disabled }, i) => (
                  <ListItemText
                    key={ i }
                    onClick={ () => {
                      !disabled && action(id, index)
                    } }
                    classes={ {
                      root: classes.itemShrink,
                      primary: disabled ? classes.disabledLink : classes.link
                    } }
                    primary={ title }
                  />
                )) }
              </Grid>
            ) }
          </Grid>
        </ListItem>
      ) }
    </div>
  )
}

export default Row
