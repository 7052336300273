import { Grid, Typography } from "@material-ui/core"
// @ts-ignore
import moment from "moment"
import * as React from "react"
import { FC, useEffect, useMemo } from "react"

import { IMetadataDatosAbiertos } from "../../../context/DatosAbiertosContext"
import { bytesToSize } from "../../../helpers/helperFunctions"
import { Button } from "../../_common"

import useStyles from "./styles"

interface IDBInformation {
  metadata: IMetadataDatosAbiertos
  fetchMetadata: () => Promise<void>
  downloadDBAction: () => Promise<string>
}

const DBInformation: FC<IDBInformation> = ({
                                             metadata,
                                             fetchMetadata,
                                             downloadDBAction
                                           }) => {
  const styles = useStyles()

  useEffect(() => {
    fetchMetadata()
  }, [])

  const handleDownloadFile = async () => {
    const url = await downloadDBAction()
    typeof window !== "undefined" && window.open(url)
  }

  const info = useMemo(() => {
    if (metadata) {
      return [
        {
          text: "Archivo",
          value: (
            <Button
              aria="Descargar base completa"
              small
              handleClick={ handleDownloadFile }
            >
              Descargar base completa
            </Button>
          )
        },
        {
          text: "Número de descargas",
          value: metadata.downloads
        },
        { text: "Licencia", value: "Dominio público" },
        { text: "Idioma", value: "Español" },
        { text: "Territorio", value: "México" },
        { text: "Institución responsable", value: "CNBV" },
        {
          text: "Última actualización",
          value: moment(metadata.uploadingDate).format("LL")
        },
        { text: "Formato", value: "XLSX" },
        { text: "Tamaño", value: bytesToSize(metadata.filesize) },
        { text: "Número de filas", value: metadata.totalReg },
        { text: "Número de columnas", value: metadata.colCount }
      ]
    }
    return []
  }, [metadata])

  return (
    <Grid item className={ styles.infoWrapper }>
      <Typography component="h6">Información de la base de datos</Typography>
      { info.map((e, i) => (
        <Grid container key={ i } className={ styles.infoItem }>
          <Grid item xs container className={ `${styles.infoItemCell} ${styles.infoItemCellTitle}` }>
            <Typography component="span">{ e.text }</Typography>
          </Grid>

          <Grid item xs container className={ styles.infoItemCell }>
            <Typography component="span">{ e.value }</Typography>
          </Grid>
        </Grid>
      )) }
    </Grid>
  )
}

export default DBInformation
