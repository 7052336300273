import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {},
  },

  dictionaryWrapper: {},

  content: {
    margin: theme.spacing(2, 0, 4, 0),
  },
}))
