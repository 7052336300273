import { Grid } from "@material-ui/core/"
import * as React from "react"
import { FC, useState } from "react"
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io"
import theme from "../../../App.theme"
import { IRowAction } from "./Row"

import useStyles from "./styles"

import Title from "./Title"

interface IListTitles {
  titles: string[]
  rowActions: IRowAction[]
  titleClick?: (i: number) => void
  orden?: boolean
}

const ListTitles: FC<IListTitles> = ({
                                       titles,
                                       rowActions,
                                       titleClick,
                                       orden
                                     }) => {
  const classes = useStyles({})
  const [titleIndexSeleccionado, setTitleIndexSeleccionado] = useState(0)

  return (
    <Grid container className={ classes.titleWrapper }>
      { titles.map((title: string, index: number) => (
        <Grid key={ index } container item xs style={ { padding: 2 } }>
          <Grid item xs>
            <Title
              title={ title }
              onClick={ () => {
                titleClick(index)
                setTitleIndexSeleccionado(index)
              } }
              withPointer
            />
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            style={ {
              marginRight: theme.spacing(1),
              width: "auto"
            } }
          >
            { titleIndexSeleccionado === index ? (
              orden ? (
                <IoMdArrowDropdown
                  style={ { color: theme.palette.text.disabled } }
                />
              ) : (
                <IoMdArrowDropup
                  style={ { color: theme.palette.text.disabled } }
                />
              )
            ) : null }
          </Grid>
        </Grid>
      )) }
      { rowActions && <Title title="Acciones" /> }
    </Grid>
  )
}

export default ListTitles
