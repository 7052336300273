export { default as DBInformation } from "./DBInformation/DBInformation"
export { default as DataControls } from "./DataControls/DataControls"
export { default as DataIndex } from "./DataIndex/DataIndex"
export { default as DataIndexENIF } from "./DataIndexENIF/DataIndexENIF"
export { default as DataIndexHistory } from "./DataIndexHistory/DataIndexHistory"
export { default as DataTabs } from "./DataTabs/DataTabs"
export { default as DBDictionary } from "./DBDictionary/DBDictionary"
export { default as HistoryChart } from "./HistoryChart/HistoryChart"
export { default as ENIFChart } from "./ENIFChart/ENIFChart"
export { default as ExportTab } from "./ExportTab/ExportTab"
export { default as GeneralTabs } from "./GeneralTabs/GeneralTabs"
export { default as InformationTab } from "./InformationTab/InformationTab"
export { default as MoreData } from "./MoreData/MoreData"
export { default as OtrosDatos } from "./OtrosDatos/OtrosDatos"
