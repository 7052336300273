import { Grid, Typography } from "@material-ui/core"
import * as React from "react"
import { FC, useMemo } from "react"
import { capitzalize } from "../../../helpers/helperFunctions"
import { IOpendataHook } from "../../../hooks/useOpendata"

import { MoreInfo } from "../../IndicadoresModule"
import { IMoreItem } from "../../IndicadoresModule/MoreInfo/MoreInfo"

import useStyles from "./styles"

interface IMoreData extends Partial<IOpendataHook> {
  idMunicipio: number
}

interface IRenderMoreData {
  sectorTitle: string
  estado: string
  municipio: string
  data: IMoreItem[]
}

const MoreData: FC<IMoreData> = ({ idMunicipio, datosAbiertos }) => {
  const styles = useStyles()

  const item = useMemo(() => {
    return datosAbiertos.find(e => e._id === idMunicipio)
  }, [datosAbiertos, idMunicipio])

  const data: IRenderMoreData[] = useMemo(() => {
    return (
      item &&
      item.data.map(e => {
        return {
          sectorTitle: e.sector,
          estado: e.columnas["estado"],
          municipio: e.columnas["municipio"],
          data: Object.entries(e.columnas).map(([label, value]): {
            label: string
            value: string | number
          } => ({
            label,
            // @ts-ignore
            value
          }))
        }
      })
    )
  }, [])

  return (
    <>
      <Grid container className={ styles.helpWrapper }>
        <Typography component="p" variant="body1">
          A continuación se muestra la información del municipio. Si deseas
          agregar información de otros sectores, utiliza las herramientas de
          búsqueda.
        </Typography>
        <Grid item container className={ styles.itemMainInfoWrapper }>
          <Grid item xs>
            <Typography variant="body1">
              Estado: <strong>{ data[0].estado || data[1].estado }</strong>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1">
              Municipio:{ " " }
              <strong>{ data[0].municipio || data[1].municipio }</strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      { data.map((e, i) => (
        <Grid container key={ i } direction="column">
          { e.sectorTitle !== "municipios" && (
            <Grid item className={ styles.titleWrapper }>
              <Typography variant="body1" component="h6">
                { capitzalize(e.sectorTitle.split("_").join(" ")) }
              </Typography>
            </Grid>
          ) }

          <Grid item>
            <MoreInfo
              data={ e.data.filter(
                e => e.label !== "id_municipio" && e.label !== "periodo"
              ) }
            />
          </Grid>
        </Grid>
      )) }
    </>
  )
}

export default MoreData
