import { Grid, Typography } from "@material-ui/core"
import * as React from "react"
import { Dispatch, FC, SetStateAction, useState } from "react"
import { IOpendataHistoryHook } from "../../../hooks/useOpendataHistory"

import { ActivityLoader } from "../../_common"
import { CategoryChecklist } from "../../_dataMgmt"
import CategoryMultipleOption from "../../_dataMgmt/CategoryMultipleOption/CategoryMultipleOption"
import Header from "./Header"
import Search from "./Search"

import useStyles, { multiSelectStyle } from "./styles"

let Multiselect = null

if (typeof window !== "undefined") {
  Multiselect = require("multiselect-react-dropdown").Multiselect
}

interface IDataControls extends Partial<IOpendataHistoryHook> {
  fetch: () => Promise<void>
  setTabValue: Dispatch<SetStateAction<number>>
  controls: IDCControl[]
  handleReset?: () => void
}

export interface IDCControl {
  type: string
  options: any[]
  renders: boolean
  title?: string
  valueSetter?: (args?: any) => void
  value?: string | number
  id?: string
  selectionCallback?: () => void
  placeholder?: string
  onSelect?: (e: any) => void
  onRemove?: (e: any) => void
  ref?: any
  selectionLimit?: number
}

const DataControls: FC<IDataControls> = ({
                                           // Getters
                                           fetch,
                                           setTabValue,
                                           loadingVariable,
                                           isSearchAvailable,
                                           // Props
                                           controls,
                                           handleReset
                                         }) => {
  const styles = useStyles()

  if (loadingVariable) {
    return <ActivityLoader light text="Cargando variables..." />
  }

  const controlType = (control: IDCControl, index: number) => {
    const controlTypes = {
      radio: (
        <CategoryMultipleOption
          key={ index }
          value={ control.value }
          setValue={ control.valueSetter }
          title={ control.title }
          options={ control.options }
          selectionCallback={ () => {
            control.selectionCallback && control.selectionCallback()
          }
          }
        />
      ),
      check: (
        <CategoryChecklist
          key={ index }
          title={ control.title }
          options={ control.options }
          selectionCallback={ control.valueSetter }
        />
      ),
      multiChips: (
        <Grid container key={ index }>
          <Grid item container className={ styles.heading }>
            <Typography component="h6">{ control.title }</Typography>
          </Grid>
          <Multiselect
            ref={ control.ref }
            id={ control.id }
            placeholder={ control.placeholder }
            closeIcon="cancel"
            emptyRecordMsg="No se encontraron coincidencias"
            displayValue="label"
            options={ control.options }
            onSelect={ control.onSelect }
            onRemove={ control.onRemove }
            avoidHighlightFirstOption
            style={ multiSelectStyle }
            selectionLimit={ control.selectionLimit }
            caseSensitiveSearch
          />
        </Grid>
      )
    }

    return control.renders && controlTypes[control.type]
  }

  return (
    <Grid container className={ styles.root }>
      <Header { ...{ handleReset } } />
      { controls.map((control, i) => controlType(control, i)) }
      <Search { ...{ fetch, setTabValue, isSearchAvailable } } />
    </Grid>
  )
}

export default DataControls
