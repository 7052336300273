import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  root: {
    "& h6": {
      margin: theme.spacing(0, 0.5),
      fontWeight: theme.typography.fontWeightBold,

      color: theme.palette.text.disabled,
    },
    [theme.breakpoints.up("md")]: {},
  },

  dictionaryWrapper: {
    margin: theme.spacing(1, 0, 4, 0),

    borderRadius: theme.roundness,
  },

  cell: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.text.disabled}`,
    alignItems: "center",

    "& span": {
      fontSize: theme.typography.body2.fontSize,
    },
  },

  cellBold: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.text.disabled}`,
    alignItems: "center",
    "& span": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}))
